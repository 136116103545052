import ReactSlider from "react-slider";
import { useState } from "react";
    
const Slider = () => {
        const [currentValue, setCurrentValue] = useState(0);
        

  return (
    <div>
      <div className="screenshot_text"><p>Screenshot Quality</p></div>
    <div className="SSRSlider_Flex">
        <ReactSlider
        className="ScreenshotResSlider"
        trackClassName="SSRSlider-track"
        thumbClassName="SSRSlider-thumb"
        markClassName="SSRSlider-mark"
            marks={1}
            min={0}
            max={4}
        defaultValue={1}
        value={currentValue}
        onChange={(value) => setCurrentValue(value)}
        />
    </div>
        <p className="SSRSlider-Value">{currentValue +1}</p>
    </div>
  );
};

export default Slider;