// Importing necessary modules and components from React and other files
import React from "react";
import SEO from "../common/SEO";
import HeaderMain from "../common/header/HeaderMain";
import Copyright from "../common/footer/Copyright";
import AppContentGrid from "../elements/content/AppContentGrid";
import SNLogo from "../elements/logo/SNLogo";
import Popup from "../elements/popup/PopUp";

// Importing CSS styles
import Darkmode2 from "../common/header/DarkmodeBackup";

import "../assets/css/main.css";

// Defining a functional component called TomsLab
const TomsLab = () => {

  localStorage.setItem('previousPage', 'App');

  return (
    <>
      {/* Setting the title of the page for SEO */}

      <SEO title="Innovate" />

      <Popup />

      {/* Main container for the page */}
      <main className="page-wrapper ">



        {/* Displaying the header with a round button style */}
        <HeaderMain btnStyle="round" HeaderSTyle="header-not-transparent" />

      

        {/* Container for the home section */}
        <div className="home">

          {/* Sub-section within the home section */}
          <div className="home__section ">


            {/* Displaying a grid of content */}
            <AppContentGrid />

          </div>
        </div>

        {/* Displaying the copyright information */}
        <div className="footer__ancor">
    <Copyright />
  </div>

      </main>
    </>
  );
};

// Exporting the TomsLab component as the default export of the module
export default TomsLab;
