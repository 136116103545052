import React from 'react';
import ContentOne from "./ContentOne";

const AppContentGrid = () => {
    return (
        <>
            <div>
                {/* Render ContentOne component */}
                <ContentOne Column="col-lg-6 col-md-6 mt--30 portfolio no-overlay" />
            </div>
        </>
    )
}

export default AppContentGrid;