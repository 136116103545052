import React from "react";

class EnableAR extends React.Component {
  EnableAR(e, AndroidModel, IOSModel, projectFolder) {
    e.preventDefault();
    console.log("Attempting AR - " + AndroidModel + " , " + IOSModel + " , " + projectFolder);

    if (AndroidModel == null) {
      console.log("TVAX: No Android Model Available!");
      return;
    }
    if (IOSModel == null) {
      console.log("TVAX: No IOS Model Available!");
      return;
    }

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    //little fix to stop refreshing on local tests
    if (window.location.host.includes("localhost")) {
       console.log("Pretending to Run AR, LocalHost unsupported")
      return;
    }

    if (isIOS) {
      // Construct the AR link
      const arLink = window.location.origin + "/3d/AR/" + IOSModel;
      
      // Create an anchor element
      const anchor = document.createElement('a');
      anchor.setAttribute('rel', 'ar');
      anchor.setAttribute('href', encodeURI(arLink));
      
      // Create an empty image element
      const img = document.createElement('img');
      anchor.appendChild(img);
      
      // Open a new window and write the content
      const newWindow = window.open('', '_blank');
      newWindow.document.write(`
        <html>
          <head>
            <title>AR View</title>
          </head>
          <body>
            <a id="ar-link" href="${anchor.href}" rel="${anchor.rel}">
              <img src="" alt="Loading Apple Quicklook...">
            </a>
            <script>
              document.getElementById('ar-link').click();
              
              // Function to close the window when AR is exited
              function closeWindowOnARExit() {
                //Trying the below per - https://jeffclayton.wordpress.com/2015/02/13/javascript-window-close-method-that-still-works/
                open(location, '_self').close();
                //window.close();
              }
    
              // Listen for the visibilitychange event
              document.addEventListener('visibilitychange', function() {
                if (document.visibilityState === 'hidden') {
                  closeWindowOnARExit();
                }
              });
    
              // Listen for the pagehide event
              window.addEventListener('pagehide', closeWindowOnARExit);
            </script>
          </body>
        </html>
      `);
      newWindow.document.close();
    }
    
    /*
    ///OLD WORKING VERSION, TRYING TO STOP PAGE RELOAD WITH MODIFIED VERSION
    if (isIOS) {
      // Handle iOS (Apple QuickLook)
      const arLink = window.location.origin + "/3d" + projectFolder + IOSModel

      //Taken this code from here to prevent thumbail view https://cwervo.com/writing/quicklook-web/#launching-without-a-preview-image-using-javascript
          const anchor = document.createElement('a');
          e.preventDefault();
          anchor.setAttribute('rel', 'ar');
          anchor.appendChild(document.createElement('img'));
          anchor.setAttribute('href', encodeURI(arLink));
          anchor.click();

      console.log(arLink);
      console.log("IOS AR should have launched")
    }*/
    
    else if (isAndroid) {
      // Handle Android (Scene Viewer)
      const arLink = window.location.origin + "/3d/AR/" + AndroidModel;
      const intentUrl = `intent://arvr.google.com/scene-viewer/1.0?file=${encodeURIComponent(arLink)}&mode=ar_only&resizable=true&title=AR%20Model#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(arLink)};end;`;
      window.location.href = intentUrl;
      console.log(arLink);
      console.log(intentUrl);
      console.log("Android AR should have launched")
    } else {
      // Handle desktop or unsupported devices
      console.log("Desktop AR shouldnt have launched")
      return;
    }
  }

  //DM - the button is created and assigned the value of the 3D File it needs to activate.
  render() {
    return (
      <div>
        <div>
          <a
            onClick={(e) => {
              this.EnableAR(
                e,
                this.props.AndroidModelName,
                this.props.IOSModelName,
                this.props.projectFolder
              );
            }} rel="ar"
          >
            <button type="button"
            className="unity__button unity__button-hide"> 
              AR
            </button>
          </a>
        </div>
      </div>
    );
  }
}

export default EnableAR;
