import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const DownloadModelButton = ({ projectFolder , glbLink }) => {

  // Handle download button click
  const handleButtonClick = () => {
    // Check if selected content and glbLink are available
    if (projectFolder && glbLink) {
      // Create a temporary link element
      const link = document.createElement("a");
      
      var siteref = 'https://tvax.app/'
      if (window.location.href.includes("dev")){
        console.log("Developer site")
        siteref = 'https://devtvax.netlify.app/'
      } else if (window.location.href.includes("local")){
        console.log("Local")
        siteref = 'https://devtvax.netlify.app/'
      } else {
        console.log("Normal Site")
      }

      // Use the URL constructor to handle URL formatting
            const downloadUrl = new URL(glbLink, siteref + "3d" + projectFolder);

            // Set the href attribute
            link.href = downloadUrl.href;
      
            // Extract the filename from the URL
            const filename = glbLink.substring(glbLink.lastIndexOf("/") + 1);
      
            // Set the download attribute to the filename
            link.download = filename;
      
            // Simulate a click on the link to trigger the download
            link.click();


    }
  };

  // Render the component
  return (
    <div>
      <div className="button-box">
        {glbLink ? (
          <button onClick={handleButtonClick} className="unity__button">
            Download
          </button>
        ) : (
          <div className="no__content"><p>N/A</p></div>
        )}
      </div>
    </div>
  );
};

export default DownloadModelButton;




