import React from 'react';
import AppContentOne from "./AppContentOne";

const ContentGrid = () => {
    return (
        <>
            <div>
                {/* Render ContentOne component */}
                <AppContentOne Column="col-lg-6 col-md-6 mt--30 portfolio no-overlay" />
            </div>
        </>
    )
}

export default ContentGrid;
