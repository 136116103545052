import React from "react"; // Import the React library.

// Import CSS styles from a stylesheet.
import "../../assets/css/main.css";
import { useParams } from "react-router-dom"; // Import the useParams hook from React Router.

// Define a functional component called ContentItem that takes two props: content and onItemClick.
const ContentItem = ({ content, onItemClick }) => {
  // Log the value of content.link to the console.

  // Define a function called handleClick, which is called when the card is clicked.
  const handleClick = () => {
    // Call the onItemClick function and pass the content prop as an argument.
    onItemClick(content);
  };

  // Use the useParams hook to access URL parameters. elementName is one of the URL parameters.

  // Render a card element with content and event handlers.
  return (
    <div className="card">
      {/* Create a link element with an "href" attribute that constructs a URL by appending "content.ProjectFolder" to "/Results". This link is associated with the "card__link" CSS class and has an "onClick" event handler that triggers the "handleClick" function when clicked. */}
      <a className="card__link" onClick={handleClick}>
        <div className="card__container">
          <div className="card__image">
            {/* Render an image with a src attribute using content.contentImage and alt attribute using content.title. */}
            <img src={content.contentImage} alt={content.title} />
          </div>
          <div className="card__info">
            {/* Render content details using dangerouslySetInnerHTML for HTML content. */}
            <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
            <h4 dangerouslySetInnerHTML={{ __html: content.subtitles }} />
            {/* Render content category. */}
          </div>
          <div className="catagory__box">
            <h5>{content.category}</h5>
            </div>
        </div>
      </a>
    </div>
  );
};

export default ContentItem; // Export the ContentItem component as the default export.
