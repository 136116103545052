import React, { useState } from "react";
import SEO from "../common/SEO";
import HeaderMain from "../common/header/HeaderMain";
import Copyright from "../common/footer/Copyright";
import Popup from "../elements/popup/PopUp";
import { Container, Row, Col } from 'react-grid-system';
import videosData from "../data/VideoContent.json";

const Video = () => {
  const [currentVideo, setCurrentVideo] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [embedCodeCopied, setEmbedCodeCopied] = useState(false);

  const openModal = (video) => {
    setCurrentVideo(video);
  };

  const closeModal = (e) => {
    // Check if the click event occurred inside the modal content
    if (e.target.classList.contains("custom-modal-content")) {
      return; // If so, do nothing
    }
    setCurrentVideo(null); // Otherwise, close the modal
    setEmbedCodeCopied(false); // Reset embedCodeCopied state
  };

  const handleEmbedClick = () => {
    const baseURL = "http://www.tvax.app";
    const iframeCode = `<iframe src="${baseURL}${currentVideo.src}" title="${currentVideo.title}" width="560" height="315" frameBorder="0" allowFullScreen></iframe>`;
    navigator.clipboard.writeText(iframeCode)
      .then(() => {
        setEmbedCodeCopied(true);
        setTimeout(() => setEmbedCodeCopied(false), 2000); // Reset embedCodeCopied after 2 seconds
      })
      .catch((error) => console.error('Failed to copy: ', error));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSelectedCategory(e.target.textContent);
  };

  const filteredVideos = selectedCategory === "All" ? videosData : videosData.filter(video => video.category === selectedCategory);

  return (
    <div>
      <SEO title="TVA DAM Home" />
      <Popup />
      <HeaderMain btnStyle="round" HeaderSTyle="header-not-transparent" />
      <main className="page-wrapper">
        <div className="category-selection">
          <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20 rwt-portfolio-filter-dark">
            <li className="list-item">
              <button onClick={handleChange} className={selectedCategory === "All" ? "current" : ""}>All</button>
            </li>
            <li className="list-item">
              <button onClick={handleChange} className={selectedCategory === "Educational" ? "current" : ""}>Teachbacks</button>
            </li>
            <li className="list-item">
              <button onClick={handleChange} className={selectedCategory === "Tutorial" ? "current" : ""}>3D Animations</button>
            </li>
            {/* Add more category buttons as needed */}
          </ul>
        </div>
        <Container>
          <Row className="video_container">
            {filteredVideos.map((video) => (
              <Col key={video.id} sm={6} md={6} >
                <div className="video-item">
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    onClick={() => openModal(video)}
                    className="thumbnail-image"
                  />
                  <h3 className="thumbnail-title">{video.title}</h3>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        {currentVideo && (
          <div className="custom-modal-overlay" onClick={(e) => closeModal(e)}>
            <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
              <video controls>
                <source src={currentVideo.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="button-row">
                <button className="modal-button" onClick={closeModal}>Close</button>
                <button className="modal-button" onClick={handleEmbedClick}>Embed</button>
              </div>
              {embedCodeCopied && <p className="embed-copied">Embed code copied to clipboard!</p>}
            </div>
          </div>
        )}
      </main>
      <div className="footer__ancor unity__frame_size">
        <Copyright />
      </div>
    </div>
  );
};

export default Video;
