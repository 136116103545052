import React from 'react';
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import { FiX } from "react-icons/fi";

const MobileHomeMenu = ({show, onClose}) => {
    var elements = document.querySelectorAll('.popup-mobile-menu2 .has-droupdown2 > a');
    var elementsTwo = document.querySelectorAll('.popup-mobile-menu2 .with-megamenu2 > a');
    for(var i in elements) {
        if(elements.hasOwnProperty(i)) {
            elements[i].onclick = function() {
                this.parentElement.querySelector('.submenu2').classList.toggle("active2");
                this.classList.toggle("open");
            }
        }
    }

    for(var i in elementsTwo) {
        if(elementsTwo.hasOwnProperty(i)) {
            elementsTwo[i].onclick = function() {
                this.parentElement.querySelector('.rn-megamenu2').classList.toggle("active2");
                this.classList.toggle("open");
            }
        }
    }
    return (
        <div className={`popup-mobile-menu2 ${show ? "active2": ""}`}>
            <div className="inner2">
                <div className="header-top2">
                    {/* <Logo 
                        image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                    /> */}
                    <div className="close-menu2">
                        <span className="close-button2" onClick={onClose}><FiX /></span>
                    </div>
                </div>
                <Nav />
            </div>
        </div>
    )
}
export default MobileHomeMenu;