import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import "../assets/scss/style.scss";

const HeroComponent = () => {
  return (
    <div className="hero">
      <video
        className="hero-video"
        autoPlay
        muted
        loop
        playsInline
        poster="path-to-poster-image.jpg"
      >
        <source src="/video/tvavideo.mp4" type="video/mp4" />
      </video>
      <div className="hero-content">
        <h2>Innovating Tomorrow, Today</h2>
        <Link to="/Home"> {/* Replace "/home" with your actual home route */}
          <button className="unity__button">Enter</button>
        </Link>
      </div>
    </div>
  );
};

export default HeroComponent;
