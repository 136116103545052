import React from 'react';

function QRButton () {
  

 

  return (
    <div >
    <button className='info__button'>
        <img src='/images/icons/QRIcon.png'/>
      </button>
    </div>
  );
} 

export default QRButton ;
