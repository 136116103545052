import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContentData from "../../data/ContentDataV2.json";

const EmbedBoxArea = ({projectFolder}) => {

  var siteref = 'https://tvax.app/'
  if (window.location.href.includes("dev")){
    console.log("Developer site")
    siteref = 'https://devtvax.netlify.app/'
  } else if (window.location.href.includes("local")){
    console.log("Local")
    siteref = 'https://devtvax.netlify.app/'
  } else {
    console.log("Normal Site")
  }
  
  return (
    <div>
      <div className="unity__embedArea">
        {projectFolder ? (
          <textarea
            rows="4"
            cols="50"
            value={"<iframe src='" + siteref + "3d" + projectFolder + "index.html'></iframe>"}
            readOnly
            onClick={(e) => e.target.select()}
          />
        ) : (
          <p>No content selected or embedLink not available.</p>
        )}
      </div>
    </div>
  );
};

export default EmbedBoxArea;
