import React, { useState, useEffect } from "react";
import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const MobileDescriptionPopup = ({ onClose, description }) => {

    
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup__close-button" onClick={onClose}>
          <FiX />
        </button>
        <div className="unity__mobile_description">
            {description ? (
              <p>{description}</p>
            ) : (
              <p>No description available</p>
            )}
          </div>
      </div>
    </div>
  );
};

export default MobileDescriptionPopup;
