import React from "react";
import "../../assets/css/main.css";

const AppContentItem = ({ content, onItemClick }) => {
  // Handle item click
  const handleClick = () => {
    onItemClick(content);
  };

  return (
<div className="card">
  <a href={content.link} className="card__link" onClick={handleClick}>
    <div className="card__container">
      <div className="card__image">
        {/* Render content image */}
        <img src={content.contentImage} alt={content.title} />
      </div>
      <div className="card__info">
        {/* Render content details */}

        <h2 dangerouslySetInnerHTML={{ __html: content.title }}/>
        <h4>{content.subtitles}</h4>
      </div>
      <div className="catagory__box">
            <h5>{content.category}</h5>
            </div>
    </div>
  </a>
</div>

  );
};

export default AppContentItem;
