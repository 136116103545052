// Importing necessary modules from React and ReactDOM
import React from 'react';
import ReactDOM from 'react-dom';

// This replaced ReactDom in React 17
import { createRoot } from 'react-dom/client'; 

// Importing the main App component
import App from './App.js';

// Importing the reportWebVitals function
import reportWebVitals from './reportWebVitals';

// Rendering the App component using createRoot NOT ReactDom
createRoot(document.getElementById('root')).render(
  <>
    <App />
  </>
);

// Measuring performance in the app using reportWebVitals
reportWebVitals();
