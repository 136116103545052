import React, { useState, useEffect } from 'react';
import lightModeIcon from '../../assets/images/icons/light-mode.png';
import darkModeIcon from '../../assets/images/icons/dark-mode.png';

const Darkmode2 = () => {
  const [theme, setTheme] = useState('light'); // Set the initial theme to 'light'

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
      document.body.classList.add(savedTheme);
    } else {
      setTheme('light');
      document.body.classList.add('light');
    }

    // Set CSS variables based on the selected theme
    document.documentElement.style.setProperty(
      '--filter-btn-bg-hover',
      theme === 'light' ? 'var(--sn-orange)' : 'yellow' // Change to yellow for dark mode
    );
    document.documentElement.style.setProperty(
      '--filter-btn-text-hover',
      theme === 'light' ? 'var(--color-white)' : 'var(--color-black)'
    );
  }, [theme]);

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.classList.toggle('light');
    document.body.classList.toggle('dark');
  };

  return (
    <button className={theme === 'light' ? 'light' : 'dark'} id="darkmode" onClick={switchTheme}>
      {/* Render the appropriate icon based on the theme */}
      {theme === 'light' ? (
        <img className="icon" src={lightModeIcon} alt="Sun images" />
      ) : (
        <img className="icon" src={darkModeIcon} alt="Moon images" />
      )}
    </button>
  );
};

export default Darkmode2;
