import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

const Logo = ({ image, image2 }) => {
  const navigate = useHistory();
  const publicURL = process.env.PUBLIC_URL;
  const previous = localStorage.getItem('previousPage');
  const [replay, setReplay] = useState(false);

  useEffect(() => {
    // Whenever the page changes, reset the replay state
    navigate.listen(() => {
      setReplay(false);
    });
  }, [navigate]);

  function checkLocation() {
    let returnURL = '';

    if (previous === null || previous === "3D") {
      returnURL = publicURL + "/Home";
      navigate.push(returnURL);
    } else if (previous === "App") {
      returnURL = publicURL + "/AppLibrary";
      navigate.push(returnURL);
    }
  }

  const handleImageLoad = () => {
    // Set replay to true to trigger the GIF replay
    setReplay(true);
  };

  return (
    <div className="logo_header">
      {/* Link to the home page */}
      {/* Render the light version of the logo */}
      <img
        className="logo-light"
        src={`${publicURL}${image}${replay ? `?${Date.now()}` : ''}`} // Add a timestamp to the URL to force reload
        alt="Corporate Logo"
        onClick={checkLocation}
        onLoad={handleImageLoad}
      />
      {/* Render the dark version of the logo */}
      <img
        className="logo-dark"
        src={`${publicURL}${image2}${replay ? `?${Date.now()}` : ''}`} // Add a timestamp to the URL to force reload
        alt="Corporate Logo"
        onClick={checkLocation}
        onLoad={handleImageLoad}
      />
    </div>
  );
}

// Prop type validation for the image prop
Logo.propTypes = {
  image: PropTypes.string,
  image2: PropTypes.string,
};

export default Logo;
