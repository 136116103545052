import PropTypes from "prop-types";
import React from 'react';

const SEO = ({ title }) => {
  return (
    <>
      {/* Set the character set to UTF-8 */}
      <meta charSet="utf-8" />

      {/* Set the page title using the provided title prop */}
      <title>{title} || TVA X</title>

      {/* Set the robots meta tag to noindex, follow */}
      <meta name="robots" content="noindex, follow" />

      {/* Set the page description */}
      <meta name="description" content="Smith + Nephew – TVA X" />

      {/* Set the viewport meta tag */}
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string
};

export default SEO;
