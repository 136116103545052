import React from 'react';
import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';

const tour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: 'class-1 class-2',
    scrollTo: { behavior: 'smooth', block: 'center' }
  }
}); 

export function startTour(){

  tour.start();

};

const UserGuide = () => {  

  //If weve already seen the tour then do not add the welcome step.
  if (localStorage.getItem('seen_tour') == null) {
    tour.addStep({
      id: '1-Welcome',
      text: '<p>Let me show you around!</p>',
      attachTo: {
      },
      classes: 'tutorial__container',
      buttons: [
        {
          text: 'Next',
          action: tour.next,
          classes:'tutorial__button',
          on: 'top'
        },
        {
          text: 'Exit',
          action: tour.cancel,
          classes:'tutorial__button',
          on: 'top'
        }
      ]
    });
  } 


      tour.addStep({
        middleware: [offset({ mainAxis: 200, crossAxis: -90 })], //?Doesnt seem to do fuckall
        getElement: '2-MainView',
        text: '<p>Once loaded, you should see your chosen 3D Model. <br> <ul><li>To Rotate around, click the left mouse button (Or touch) and drag.</li><li>To Zoom in, Scroll in or out with your middle mouse wheel, or 2 finger pinch on mobile.</li><li>To Walk around, Click and Drag the Middle Mouse button, or 3-Finger Drag on mobile.</li></ul></p>',
        attachTo: {
            element: '',
            on : 'left'    
        },
        classes: 'tutorial__container',
        buttons: [
          {
            text: 'Next',
            action: tour.next,
            classes:'tutorial__button'
          },
          {
            text: 'Exit',
            action: tour.cancel,
            classes:'tutorial__button',
            on: 'top'
          }
        ]
      });

      tour.addStep({
        getElement: '3-UIButtons',
        text: '<p>These buttons will help you interact with the 3D model, once loaded on the page.</p>',
        attachTo: {
            element: '#tutorial__anchor_buttons',
            on : 'left',    
        },
        classes: 'tutorial__container',
        buttons: [ 
          { 
            text: 'Next',
            action: tour.next,
            classes:'tutorial__button'
          },
          {
            text: 'Exit',
            action: tour.cancel,
            classes:'tutorial__button',
            on: 'top'
          }
        ]
      });

      tour.addStep({
        getElement: '4-Renderbutton',
        text: '<p>This button will create a transparent screenshot of the 3D model in which ever angle you decide to put it in.</p>',
        attachTo: {
            element: '#tutorial__anchor_renderbutton',
            on : 'left',    
        },
        classes: 'tutorial__container',
        buttons: [
          {
            text: 'Next',
            action: tour.next,
            classes:'tutorial__button'
          },
          {
            text: 'Exit',
            action: tour.cancel,
            classes:'tutorial__button',
            on: 'top'
          }
        ]
      });

      tour.addStep({
        getElement: '5-Embedbutton',
        text: '<p>Clicking this button will copy the embed code to your clipboard ready for you to insert into another program.</p>',
        attachTo: {
            element: '#tutorial__anchor_embedbutton',
            on : 'left',    
        },
        classes: 'tutorial__container',
        buttons: [
          {
            text: 'Next',
            action: tour.next,
            classes:'tutorial__button'
          },
          {
            text: 'Exit',
            action: tour.cancel,
            classes:'tutorial__button',
            on: 'top'
          }
        ]
      });

      tour.addStep({
        getElement: '6-Downloadbutton',
        text: '<p>Ths button will download the GLB file of the 3D model you have choosen.</p>',
        attachTo: {
            element: '#tutorial__anchor_downloadbutton',
            on : 'left',    
        },
        classes: 'tutorial__container',
        buttons: [
          {
            text: 'Next',
            action: tour.next,
            classes:'tutorial__button'
          },
          {
            text: 'Exit',
            action: tour.cancel,
            classes:'tutorial__button',
            on: 'top'
          }
        ]
      });
      tour.addStep({
        getElement: '7-slider',
        text: '<p>The slider controls the quality of the screenshot. Lower values for less quality and higher for better quality.</p>',
        attachTo: {
            element: '#tutorial__anchor_slider',
            on : 'left',    
        },
        classes: 'tutorial__container',
        buttons: [
          {
            text: 'Next',
            action: tour.next,
            classes:'tutorial__button'
          },
          {
            text: 'Exit',
            action: tour.cancel,
            classes:'tutorial__button',
            on: 'top'
          }
        ]
      });
      tour.addStep({
        getElement: '8-backButton',
        text: '<p>Use the TVA logo to go back to the main 3D page.</p>',
        attachTo: {
            element: '.logo_header',
            on : 'left',    
        },
        classes: 'tutorial__container',
        buttons: [
          {
            text: 'Next',
            action: tour.next,
            classes:'tutorial__button'
          },
          {
            text: 'Exit',
            action: tour.cancel,
            classes:'tutorial__button',
            on: 'top'
          }
        ]
      });

      tour.addStep({
        getElement: '9-End',
        text: '<p>Take a look around, and have fun!</p>',
        attachTo: {
        },
        classes: 'tutorial__container',
        buttons: [
          {
            text: 'Finish',
            action: tour.next,
            classes:'tutorial__button'
          }
        ]
      });

        if (localStorage.getItem('seen_tour') == null) {
          tour.start();
          localStorage.setItem('seen_tour', 'true');
          console.log('Tour Starting!');
        } else {
          console.log("Tour Already Viewed.")
        };

  return (
    <div>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/shepherd.js@8.3.1/dist/css/shepherd.css"/> 
    </div>
  )
}

export default React.memo(UserGuide);

//React.memo used to avoid calling components twice. Isolate the function into a component then return like this. (BugFix for the Shepard Guide)