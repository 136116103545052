import React from 'react'
import {startTour} from '../unity/components/UserGuide';

const InfoButton = (_, {}) => {
  const handleButtonClick = () => {
    console.log("rerunning tour")
    startTour();
  };

  return (
    <div>
      <button onClick={handleButtonClick} className='info__button'>
        <img src='/images/icons/info.png' alt='Info' />
      </button>
    </div>
  );
};

export default InfoButton
