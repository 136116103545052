import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


const EmbedModelButton = ({ projectFolder }) => {

  const handleButtonClick = () => {
    if (projectFolder) {

      var siteref = 'https://tvax.app/'
      if (window.location.href.includes("dev")){
        console.log("Developer site")
        siteref = 'https://tvax.app/'
      } else if (window.location.href.includes("local")){
        console.log("Local")
        siteref = 'https://tvax.app/'
      } else {
        console.log("Normal Site")
      }

      //DM - We build the iFrame Link completely here instead of in the JSON.
      let iFrameLinkA = "<iframe src='" + siteref + "3d";
      let iFrameLinkB = "index.html'></iframe>";
      let iFrameLink = iFrameLinkA  + projectFolder + iFrameLinkB;

      // Copy the embed link to clipboard
      navigator.clipboard.writeText(iFrameLink)
        .then(() => {
          console.log('Embed link copied to clipboard:', iFrameLink);
          alert('Embed link copied!');
        })
        .catch((error) => {
          console.error('Failed to copy embed link to clipboard:', error);
          alert('Failed to copy embed link.');
        });
    }
  };

  return (
    <div>
      <div className="button-box">
        {projectFolder ? (
          <button onClick={handleButtonClick} className="unity__button">
           Embed
          </button>
        ) : (
          <p>No content selected or embedLink not available.</p>
        )}
      </div>
    </div>
  );
};

export default EmbedModelButton;
