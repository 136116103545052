import { useState, useRef, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import MobileHomeMenu from './MobileHomeMenu';
import Darkmode2 from "./DarkmodeBackup";
import BugReport from "./BugReportButton";

const HeaderMain = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const ref = useRef();
  const [check, setCheck] = useState(true);

  // TP : Function to handle the click event for opening/closing the mobile menu
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  // TP: Removed duplicated ref and check variables

  useEffect(() => {
    // Set the initial theme to light when the component mounts
    if (window.localStorage) {
      const theme = window.localStorage.getItem('theme');
      if (!theme) {
        window.localStorage.setItem('theme', 'light');
      }
    }
  }, []);

  return (
    <>
      <header ref={ref} className={`rn-header header-default header-left-align ${HeaderSTyle} sticky`} style={{backgroundColor: 'transparent'}}>
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-6 col-4 position-static">
              <div className="header-left d-flex">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                />
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-8">
              <div className="header-right">
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    {/* Adds the hamburger menu for mobile view */}
                    <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                  </div>
                  
                </div>
                <Darkmode2 />
                <BugReport />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Enables Mobile Menu */}
      <MobileHomeMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};

export default HeaderMain;
