import React, { useState, useEffect } from 'react';
import SNLogo from '../elements/logo/SNLogo';


const LoadingScreen = () => {
  
  

  useEffect(() => {
    
  }, []);

  return (
    <div className="loading-screen">
      {/* <SNLogo /> */}
      <img src="/images/logo/LogoAnimation2.gif"></img>
      {/* <h2>{slogan}</h2> */}
    </div>
  );
};

export default LoadingScreen;
