import React from 'react';

function EmailButton() {
  const sendEmail = () => {
    try {
      const subject = encodeURIComponent('TVA X Support');
      const body = encodeURIComponent('Please let us know if you are having any difficulties with the site.');

      // Construct the mailto URL
      const mailtoUrl = `mailto:TVAXSupport@groups.smith-nephew.com?subject=${subject}&body=${body}`;

      // Log the mailto URL for debugging purposes
      console.log(`Attempting to send email using URL: ${mailtoUrl}`);

      // Here you could add additional logging, e.g., sending a log to a server
      // Example:
      // fetch('/log-email-attempt', { method: 'POST', body: JSON.stringify({ mailtoUrl }) });

      // Open the user's default email client with the pre-filled email
      window.location.href = mailtoUrl;

      // Log success message
      console.log('Email client should now be open.');

    } catch (error) {
      // Log any errors that occur during the process
      console.error('Error sending email:', error);
    }
  };

  return (
    <div>
      <button onClick={sendEmail} className='info__button'>
        <img src='/images/icons/email.png' alt='Email Icon' />
      </button>
    </div>
  );
}

export default EmailButton;
