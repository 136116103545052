import React from "react";
import { Link } from "react-router-dom";

import "../../assets/scss/style.scss";

const Nav = () => {
  return (

    <div>
    <ul className="mainmenu">
      {/* Home link */}
      <li className="has-dropdown">
        <Link to="Home">3D Library </Link>
      </li>
      <li className="has-dropdown">
        <Link to="AppLibrary">App Library</Link>
      </li>
      <li className="has-dropdown">
        <Link to="Video">Video Library</Link>
      </li>
      {/* Add more menu items as needed */}
    </ul>
    </div>
  );
};

export default Nav;
