import React, { useState, useEffect } from 'react';
import '../../assets/css/main.css';

const Popup = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const hasAgreed = localStorage.getItem('hasAgreed');
    if (hasAgreed) {
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('hasAgreed', true);
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="popup">
          <div className="popup-content">
            <h2>Disclaimer</h2>
            <div className="popup-text">
              <div className="popup-text-content">
                <p>
                  The information presented is solely for informational and educational purposes. Promotion, advertising and use of Smith+Nephew products is to be on-label and consistent with authorized indications and intended uses as stated in the product's Instructions for Use (IFU).
                  The information presented is confidential and/or for internal use only. It is not intended for distribution to customers, surgeons, patients, or other external parties. Do not display or distribute without the written consent of the Legal Department. Smith+Nephew does not provide medical advice.
                </p>
              </div>
            </div>
            <button onClick={handleClose} className='unity__button'>I Agree</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
