import React from 'react';
import bugIcon from '../../assets/images/icons/bug.png';

const BugReportButton = () => {
  const sendBugReport = () => {
    const emailAddress = 'TVAXSupport@groups.smith-nephew.com'; // Specify the email address to send bug reports to
    const subject = encodeURIComponent('TVAX Bug Report');
    const body = encodeURIComponent('Please describe the bug you encountered:');

    // Open the default email app with pre-filled subject and body
    window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  };

  return (
    <button className="darkmode" id="darkmode" onClick={sendBugReport} title="Submit Bug">
      <img className="icon" src={bugIcon} alt="Bug Icon" />
    </button>
  );
};

export default BugReportButton;
