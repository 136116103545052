import React from 'react';
import { Link } from "react-router-dom";

const Copyright = () => {
    return (
        <div className="copyright-style-one">
            <div className="container_disclaimer">
                                <Link to="Disclaimer">Disclaimer</Link>
                    </div>
                            {/* Copyright text */}
                            <div className="container_text">
                                © {new Date().getFullYear()} TVA X - Smith+Nephew
                            </div>
                    
              
        
        </div>
    );
};

export default Copyright;
