import React, { useState, useEffect } from "react";
import ContentDataV2 from "../../data/ContentDataV2.json";
import ContentItem from "./ContentItem";
import { useHistory, Link } from "react-router-dom";

// Filter options
const filters = [
  {
    id: 1,
    text: "All",
  },
  {
    id: 2,
    text: "Wound",
  },
  {
    id: 3,
    text: "Ortho",
  },
  {
    id: 4,
    text: "Anatomy",
  },
  {
    id: 5,
    text: "Sports",
  },
];

const ContentOne = () => {
  const history = useHistory();
  const [getAllItems] = useState(ContentDataV2.content);
  const [dataVisibleCount, setDataVisibleCount] = useState(20);
  const [dataIncrement] = useState(10);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.slice(0, dataVisibleCount));
  }, []);

  // Handle filter change
  const handleChange = (e) => {
    e.preventDefault();
    const selectedFilter = e.target.textContent.toLowerCase();
    setActiveFilter(selectedFilter);

    let tempData;
    if (selectedFilter === filters[0].text.toLowerCase()) {
      tempData = getAllItems.slice(0, dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) => data.category.toLowerCase() === selectedFilter
      ).slice(0, dataVisibleCount);
    }
    setVisibleItems(tempData);
  };

  // Handle item click
  const handleItemClick = (content) => {
    history.push("/Results/" + content.id + "/", { content });
    console.log("Content Selected, Loading Content...")
    console.log(content)
  };

  // Load more items
  const handleLoadMore = () => {
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
      return;
    }
    setDataVisibleCount((prevCount) => prevCount + dataIncrement);
    setVisibleItems(getAllItems.slice(0, dataVisibleCount + dataIncrement));
  };

  return (
    <>
      <div className="">
        <div className="col-lg-12">
          {/* Render filter buttons */}
          <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20 rwt-portfolio-filter-dark">
            {filters.map((filter) => (
              <li className="list-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter ? "current" : ""
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="home__section">
        <div className="home__section__content">
          {/* Render content items without random order */}
          {visibleItems.map((item) => (
            <div key={item.id} className="content-item-wrapper">

              {/* <Link to={`/Results${item.ProjectFolder}`} className="card__link"> */}
                {/* ... */}
                <ContentItem content={item} onItemClick={handleItemClick} />
              {/* </Link> */}

            </div>
          ))}
        </div>
      </div>

      {/* Render "Load More" button */}
      {!noMorePost && dataVisibleCount >= 40 && (
        <div className="load-more">
          <button onClick={handleLoadMore}>Load More</button>
        </div>
      )}
    </>
  );
};

export default ContentOne;
