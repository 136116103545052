import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ContentDataV2 from "../data/ContentDataV2.json";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import Copyright from "../common/footer/Copyright";
import { EmbedModelButton, DownloadModelButton, UnityRenderButton, EmbedBoxArea } from "../unity/UnityComponents";
import Slider from '../unity/components/SSRSlider.js'
import UserGuide from "../unity/components/UserGuide";
import InfoButton from "../elements/InfoButton";
import EmailButton from "../elements/EmailButton";
import QRButton from "../elements/QRButton.js";

const Results = () => {
  const history = useHistory();
  const { elementName } = useParams(); // Accept URL Params from Home Page
  const [selectedContent, setSelectedContent] = useState(null);

  useEffect(() => {
    // Function to find content by key and set it in the state
    const findContentByKey = (key) => {
      for (const contentItem of ContentDataV2.content) {
        console.log(contentItem);
        if (contentItem.id.includes(key)) {

          console.log("FOUND" + contentItem);
          return contentItem;
        }
      }
      return null; // If not found
    };

    // Try to get content from history state, fallback to URL parameter
    const getContentFromHistoryOrURL = () => {
      const state = history.location.state ?? { content: findContentByKey(elementName) }; // Provide a default value for content

      if (state && state.content) {
        return state.content;
      } else {
        return findContentByKey(elementName);
      }
    };

    const content = getContentFromHistoryOrURL();
    setSelectedContent(content);

  }, [elementName, history]);

  return (
    <div>

      <script
        type="module"
        src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
      ></script>

      {/*Commented userguide out for now, it doesnt really work for mobiles. */}
      {/*<UserGuide /> */}

      <SEO title="TVA X Home" />
      <main className="page-wrapper ">
        
        {/* <HeaderTopNews /> */}
 
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        
        {selectedContent && selectedContent.ProjectFolder ? (
          
          <div >
            <div className="unity_col-lg-8">
             <div className='unity_container'>
              <div className='unity_iframe__container '>       
                <iframe title="UnityIframe" className='iframe_new unity-iframe' src={"/3d" + selectedContent.ProjectFolder + "index.html"} allow="autoplay"></iframe>
               </div>
               <div id="tutorial__anchor_model"></div>
        </div> 
            </div>
            <div className="unity_col-lg-4 unity__frame_size">
            <div >
            <div className="unity__info_textarea unity__frame_size ">
            <h3 dangerouslySetInnerHTML={{ __html: selectedContent.title }}/>
            <p className="unity__textCSSFix" v-html="content" dangerouslySetInnerHTML={{ __html: selectedContent.description}}/>
              </div>
              <div id="tutorial__anchor_buttons" className="unity__button-container unity__frame_size "> 
              <div className="unity_button1">
                <div id="tutorial__anchor_renderbutton"><UnityRenderButton device="Desktop" id="SSRSDesktop" /></div>  
              </div> 
              <div className="unity_button2">
                <div id="tutorial__anchor_embedbutton"><EmbedModelButton projectFolder={selectedContent.ProjectFolder} /></div>  
              </div> 
              <div className="unity_button3">
                <div id="tutorial__anchor_downloadbutton"><DownloadModelButton projectFolder={selectedContent.ProjectFolder} glbLink={selectedContent.glbLink}/></div>   
              </div> 
              </div>
              <div className="unity__textbox_area unity__frame_size">
                <div id="tutorial__anchor_slider"></div><Slider />
              </div>
              <div className="unity__textbox_area unity__frame_size ">
              <EmbedBoxArea projectFolder={selectedContent.ProjectFolder} />
              </div>
              <div className="info__button-container unity__frame_size">
              <InfoButton className="info__button"/>
              <EmailButton />
              {/* <QRButton /> QR Button For Developement */}

              </div>
              </div>
            </div>
          </div>
        ) : (
          <p>It's not here 404</p>
        )}

      </main>
              {/* Displaying the copyright information */}
              <div className="footer__ancor unity__frame_size">
    {/* <Copyright /> */}
  </div>
    </div>
  );
};

export default React.memo(Results);
