import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArButton from "../../elements/ar_button/ArButton";
import {
  EmbedModelButton,
  DownloadModelButton,
  UnityRenderButton,
  Slider,
} from "../../unity/UnityComponents";
import MobileDescriptionPopup from "../../elements/popup/MobileDescriptionPopup"; // Import your popup component
import InfoButton from "../../elements/InfoButton";
import ContentDataV2 from "../../data/ContentDataV2.json";
import QRButton from "../../elements/QRButton";

const MobileMenu = ({ show, onClose }) => {
  const history = useHistory();
  const [selectedContent, setSelectedContent] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupText, setPopupText] = useState(""); // State for the text in the popup
  const { elementName } = useParams(); // Accept URL Params from Home Page

  //ChatGPT Version, uses the history, fallback to json key.
  useEffect(() => {
    // Function to find content by key
    const findContentByKey = (key) => {
      return (
        ContentDataV2.content.find((contentItem) =>
          contentItem.id.includes(key)
        ) || null
      );
    };

    // Try to get content from history state, fallback to URL parameter
    const state = history.location.state ?? {
      content: findContentByKey(elementName),
    };
    const content =
      state && state.content ? state.content : findContentByKey(elementName);

    setSelectedContent(content);
  }, [elementName, history]);

  /*Old Mobile version resulting in NA
  useEffect(() => {
    const state = history.location.state;

    if (state && state.content) {
      setSelectedContent(state.content);
    } else {
      setSelectedContent(null);
    }
  }, []);

  useEffect(() => {
  }, [selectedContent]);*/

  // Function to toggle the visibility of the popup
  const togglePopup = (text) => {
    setPopupText(text); // Set the text for the popup
    setPopupVisible(!isPopupVisible);
  };

  React.useEffect(() => {
    const dropdownLinks = document.querySelectorAll(
      ".popup-mobile-menu .has-droupdown > a"
    );
    const megaMenuLinks = document.querySelectorAll(
      ".popup-mobile-menu .with-megamenu > a"
    );

    dropdownLinks.forEach((link) => {
      link.addEventListener("click", handleDropdownClick);
    });

    megaMenuLinks.forEach((link) => {
      link.addEventListener("click", handleDropdownClick);
    });

    return () => {
      dropdownLinks.forEach((link) => {
        link.removeEventListener("click", handleDropdownClick);
      });

      megaMenuLinks.forEach((link) => {
        link.removeEventListener("click", handleDropdownClick);
      });
    };
  }, []);

  const handleDropdownClick = (event) => {
    event.target.classList.toggle("open");
  };

  return (
    <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
      <div className="inner">
        <div className="header-top">
          <div className="close-menu"></div>
        </div>
        {/* FULL BUTTON CONTAINER STARTS HERE FOR SLIDER UP EFFECT ------------------ */}
        <div className="unity__mobie_fullcontainer">
          {/* FLEX BUTTON CONTAINER 2 STARTS HERE ------------------ */}
          <div className="unity__mobile_flex-container2">
            <div id="tutorial__anchor_buttons">
              <div className="flex-item3">
                {selectedContent && selectedContent.glbLink ? (
                  <ArButton
                    AndroidModelName={selectedContent.glbLink}
                    IOSModelName={selectedContent.iosLink}
                    projectFolder={selectedContent.ProjectFolder}
                  />
                ) : (
                  <div className="no__content">
                    <p>N/A</p>
                  </div>
                )}
              </div>
            </div>

            {selectedContent ? (
              <div className="flex-item6">
                {" "}
                <div className="flex-item4">
                  <div id="tutorial__anchor_downloadbutton">
                    <DownloadModelButton
                      projectFolder={selectedContent.ProjectFolder}
                      glbLink={selectedContent.glbLink}
                    />{" "}
                  </div>
                </div>
              </div>
            ) : (
              <div className="no__content">
                <p>N/A</p>
              </div>
            )}

            <div className="flex-item5">
              {" "}
              {/* Button to open the popup */}
              <button
                onClick={() =>
                  togglePopup("This is the popup text for MobileMenu.")
                }
                className="unity__button"
              >
                Description
              </button>
              {/* Render the popup when it's visible*/}
              {isPopupVisible && (
                <MobileDescriptionPopup
                  text={popupText}
                  onClose={() => setPopupVisible(false)}
                  description={selectedContent.description}
                />
              )}
            </div>
          </div>
          {/* FLEX BUTTON CONTAINER 2 ENDS HERE ------------------ */}

          {/* FLEX BUTTON CONTAINER 3 STARTS HERE ------------------ */}
          <div className="unity__mobile_flex-container3">
            {selectedContent ? (
              <div className="flex-item6">
                <div id="tutorial__anchor_embedbutton">
                  <EmbedModelButton
                    projectFolder={selectedContent.ProjectFolder}
                  />
                </div>
              </div>
            ) : (
              <div className="no__content">
                <p>N/A</p>
              </div>
            )}
            <div className="flex-item7">
              <div id="tutorial__anchor_renderbutton">
                <UnityRenderButton />
              </div>
            </div>
            <div className="flex-item8">
              <InfoButton className="info__button" />
            </div>
            <div className="flex-item8">
              <QRButton />
            </div>
          </div>

          {/* FLEX BUTTON CONTAINER 3 ENDS HERE ------------------ */}

          {/* FLEX BUTTON CONTAINER 1 STARTS HERE ------------------ */}
          <div className="unity__mobile_flex-container1">
            <div className="flex-item2">
     
                <div className="unity__slider_Mobile"> <div id="tutorial__anchor_slider">
                  <Slider />
                </div>
              </div>
            </div>
          </div>
          {/* FLEX BUTTON CONTAINER 1 ENDS HERE ------------------ */}

          {/*FULL BUTTON CONTAINER ENDS HERE */}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
