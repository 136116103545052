import React from "react";

class UnityRenderButton extends React.Component {

  UnityPhotoButton = (device) => {

    // TP** Send a message to the iframe to trigger the second Unity function
    const unityIframe = document.querySelector(".unity-iframe");
    var ScreenshotResSliderVal = document.querySelectorAll(".SSRSlider-Value");

    if (device === "Mobile"){
      ScreenshotResSliderVal = ScreenshotResSliderVal[0].textContent
      
    } else {
      ScreenshotResSliderVal = ScreenshotResSliderVal[1].textContent
    }

    unityIframe.contentWindow.postMessage({ type: 'UnityPhotoButton', ScreenshotResSliderVal }, '*');

    console.log("sending screenshot message to + " + unityIframe);
  }

  render() {
    return (
      <div>
        <div>
          <button className="unity__button" onClick={() => this.UnityPhotoButton(this.props.device)}>
            Screenshot
          </button>
        </div>
      </div>
    );
  }
}

export default UnityRenderButton;
