import React from "react";
import SEO from "../common/SEO";
import HeaderMain from "../common/header/HeaderMain";
import Copyright from "../common/footer/Copyright";
import "../assets/css/main.css";

const Disclaimer = () => {
  return (
    <>
      <SEO title="TVA DAM Home" />
      <main className="page-wrapper">
        <HeaderMain btnStyle="round" HeaderSTyle="header-not-transparent" />
        <div className="disclaimer">
          <div className="disclaimer__container">
            <h3>
              The information presented is solely for informational and
              educational purposes. <p></p>
              Promotion, advertising and use of Smith+Nephew products is to be
              on-label and consistent with authorized indications and intended
              uses as stated in the product's Instructions for Use (IFU). The
              information presented is confidential and/or for internal use
              only. 
              <p></p>It is not intended for distribution to customers, surgeons,
              patients, or other external parties. Do not display or distribute
              without the written consent of the Legal Department. 
              <p></p>Smith+Nephew
              does not provide medical advice. These training materials are not
              intended to serve as medical advice nor to be trusted as
              verifiably factually accurate resources.
            </h3>
          </div>
        </div>
      </main>
    </>
  );
};
export default Disclaimer;
